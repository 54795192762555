import './packages/Morvin/dist/assets/css/bootstrap.min.css';
import './packages/Morvin/dist/assets/css/icons.min.css';
import './packages/Morvin/dist/assets/css/app.min.css';

//import './packages/Morvin/dist/assets/libs/jquery/jquery.min.js';
//import './packages/Morvin/dist/assets/libs/bootstrap/js/bootstrap.bundle.min.js';
//import './packages/Morvin/dist/assets/libs/metismenu/metisMenu.min.js';
//import './packages/Morvin/dist/assets/libs/simplebar/simplebar.min.js';
//import './packages/Morvin/dist/assets/libs/node-waves/waves.min.js';
//import './packages/Morvin/dist/assets/js/app.js';

//const imagesContext = require.context('./packages/Morvin/dist/assets/images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
//imagesContext.keys().forEach(imagesContext);